* {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
}

html {
  font-size: 16px;
  background-color: #f2f2f7;
  width: 100%;
  height: 100vh;
}

:root {
  --widthMenu: 300px;
  --widthContent: 1060px;

  --width-mobile-content: 560px;

  --color-black: #000000;
  --color-black-alpha-30: rgba(0, 0, 0, 0.06);
  --color-black-alpha-70: rgba(0, 0, 0, 0.52);
  /* Черный */
  --color-white: #ffffff;
  --color-white-alpha-50: #ffffffa8;
  /* Белый */
  --color-gray-100: #f5f5f5;
  --color-gray-100-alpha-50: #f5f5f5a8;
  /* Светло-серый 100 */
  --color-gray-200: #ebebeb;
  --color-gray-200-alpha-50: #ebebeba8;
  /* Светло-серый 200 */
  --color-gray-300: #d8d8d8;
  /* Светло-серый 300 */
  --color-gray-400: #c3c3c3;
  /* Светло-серый 400 */
  --color-gray-500: #aeaeae;
  /* Светло-серый 500 */
  --color-gray-600: #999999;
  /* Светло-серый 600 */
  --color-gray-700: #7e7e7e;
  /* Светло-серый 700 */
  --color-gray-800: #666666;
  /* Темно-серый 800 */
  --color-gray-900: #333333;
  --color-gray-900-alpha-30: rgba(51, 51, 51, 0.04);
  /* Темно-серый 900 */
  --color-success: #34c759;
  --color-success-alpha-30: rgba(52, 199, 89, 0.06);
  /* Успех */
  --color-alarm: #ff3b30;
  --color-alarm-alpha-30: rgba(255, 58, 48, 0.06);
  /* Тревога */
  --color-alarm-informBg: #ff3a3015;
  /* Тревога фон виджета*/
  --color-warning: #ffc107;
  --color-warning-alpha-30: rgba(255, 193, 7, 0.09);
  /* Предупреждение */
  --color-info: #17a2b8;
  /* Информация */
  --color-primary: #007aff;
  --color-primary-alpha-30: rgba(0, 122, 255, 0.06);
  /* --color-primary: #9F22E6; */
  --color-icon: #6425f7;
  --color-icon2: #9f22e6;
  /*Основной цвет контролов  */
  --gradient-primary: linear-gradient(45deg, #9f22e6 0%, #6425f7 80%);

  --gradient-primary-2: linear-gradient(
    45deg,
    #fb5085 0%,
    #c740ca 30%,
    #3478ff 100%
  );
  /* Основной цвет */
  --color-priority: #d2e8ff;
  --color-control-active: #ecf1f7;
  --color-control-active-icon: #9b9fa3;
}

h1 {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  line-height: 1.2;
  /* Другие свойства стиля для h1 */
}

h2 {
  font-size: 1.8rem;
  font-weight: 500;
  color: var(--color-gray-900);
  /* Другие свойства стиля для h2 */
}

h3 {
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: 500;
  color: var(--color-gray-900);
  /* Другие свойства стиля для h3 */
}

h4 {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--color-black);
  /* Другие свойства стиля для h4 */
}

h5 {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-black);
  /* Другие свойства стиля для h5 */
}

h6 {
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-black);
  /* Другие свойства стиля для h6 */
}

/* Основной стиль */
.p-primary {
  font-size: 1rem;
  line-height: 1.2;
  color: var(--color-black);
  /* Черный цвет текста */
}

/* Заголовочный стиль */
.p-heading {
  font-size: 1.5rem;
  line-height: 1.2;
  color: #222;
  /* Темно-серый цвет текста */
  font-weight: bold;
}

/* Акцентный стиль */
.p-accent {
  font-size: 0.9rem;
  line-height: 1.3;
  color: var(--color-warning);
  /* Оранжевый цвет текста */
  font-weight: bold;
}

/* Легкий стиль */
.p-light {
  font-size: 0.8rem;
  line-height: 1.3;
  color: var(--color-black-alpha-70);
  /* Светло-серый цвет текста */
}

/* Легкий стиль */
.p-mini {
  font-size: 0.7rem;
  line-height: 1.3;
  color: var(--color-gray-800);
  /* Светло-серый цвет текста */
}

/* Выделенный стиль */
.p-highlight {
  font-size: 1rem;
  line-height: 1.5;
  color: var(--color-alarm);
  /* Красный цвет текста */
  /* text-decoration: underline; */
  /* Подчеркивание текста */
}

a {
  text-decoration: none;
  color: var(--color-primary);
}

a:hover {
  /* text-decoration: underline; */
  text-decoration: none;
}

/* Menu */
.private_menu {
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  width: var(--widthMenu);
  height: 100vh;
  /* background-color: var(--color-white); */
}

@media (max-width: var(--widthContent)) {
  .private_menu {
    display: none;
  }
}

/* контейнер для контента */

.itemTypeBIke {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  background-color: var(--color-gray-100);
  border-radius: 4px;
}

.contactItem-icon span {
  font-size: 3rem;
}

.autorCheckItem {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
}

.main-box-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.main-content-box {
  width: var(--widthContent);
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 32px;
  padding: 32px;
  border-radius: 24px;
  box-sizing: border-box;
}

.content-box {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
  box-sizing: border-box;
  width: 100%;
}

.main-content-box .items {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  flex: 1;
}

.content-box.banner {
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-box p {
  /* line-height: 24px; */
  /* list-style-type: none; */
}

.custom-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 90%;
}

.custom-list-item {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: flex-start;
}

.custom-list-point {
  padding: 0 16px 0 0;
}

.content-box h2 {
  line-height: 40px;
  width: 75%;
}

.content-box h3 {
  font-size: 24px;
  line-height: 32px;
  width: 75%;
  padding-top: 16px;
}

/* контейнер для контента end*/

/* tooltip */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.tooltip .tooltip-text {
  font-size: 14px;
  font-weight: 400;
  visibility: hidden;
  width: 120px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* tooltip end */
/* html controls */
/* button {
    display: flex;
    flex-direction: row;
} */

ul li:last-child {
  border: none;
}

/* html controls end*/
/* Button */
/* .button-default {
    padding: 8px 16px;
} */

.button-search-map {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 24px;
  border-radius: 8px;
  border: none;
  color: var(--color-gray-700);
  background-color: var(--color-white);
  font-size: 1rem;
  cursor: pointer;
}

.button-reservation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: linear-gradient(45deg, #9f22e6 0%, #6425f7 80%);
  color: var(--color-white);
  border: 0;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 300;
  transition: linear-gradient 0.3s ease-in-out;
  cursor: pointer;
}

.button-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button-icon p {
  padding-right: 8px;
  white-space: nowrap;
}

.button-icon i {
  padding-left: 0px;
}

.button-quantityBikes {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  aspect-ratio: 1/1;
  border-radius: 80px;
  border: 0;
  background-color: var(--color-gray-200);
  transition: background-color 0.5s ease;
  cursor: pointer;
}

.popupButton {
  /* position: absolute; */
  /* bottom: 8px;
    left: 8px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 0 32px;
  border-radius: 80px;
  border: none;
  font-size: 1em;
  font-weight: 500;
  height: 56px;
  background-color: var(--color-black);
  color: var(--color-white);
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;
}

.wigetProdictCard-price-payToRental:hover .button-quantityBikes {
  background-color: var(--color-black);
  transition: background-color 0.5s ease;
}

.wigetProdictCard-price-payToRental:hover .material-symbols-outlined {
  color: var(--color-white);
  transition: background-color 0.5s ease;
}

.button-alarm {
  border: 0px;
  padding: 8px 16px;
  background-color: var(--color-alarm);
  color: var(--color-white);
  border-radius: 80px;
  font-size: 1em;
  white-space: nowrap;
  text-transform: lowercase;
}

.button-alarm::first-letter {
  text-transform: uppercase;
}

.button-warning {
  border: 0px;
  padding: 8px 16px;
  background-color: var(--color-warning);
  color: var(--color-white);
  border-radius: 80px;
  font-size: 1em;
  white-space: nowrap;
  text-transform: lowercase;
}

.button-warning::first-letter {
  text-transform: uppercase;
}

.button-success {
  border: 0px;
  padding: 8px 16px;
  background-color: var(--color-success);
  color: var(--color-white);
  border-radius: 80px;
  font-size: 1em;
  white-space: nowrap;
  text-transform: lowercase;
}

.button-success::first-letter {
  text-transform: uppercase;
}

.button-disable {
  border: 0px;
  padding: 8px 16px;
  background-color: var(--color-gray-300);
  color: var(--color-white);
  border-radius: 80px;
  font-size: 1em;
  white-space: nowrap;
  text-transform: lowercase;
}

.button-disable::first-letter {
  text-transform: uppercase;
}

.button-default {
  border: 0px;
  padding: 8px 16px;
  background-color: var(--color-primary);
  color: var(--color-white);
  border-radius: 80px;
  font-size: 1em;
  white-space: nowrap;
  text-transform: lowercase;
}

.button-search-console {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  min-height: 56px;
  padding: 0 16px;
  border-radius: 8px;
  border: 0;
  background-color: var(--color-control-active);
  font-size: 1rem;
  color: var(--color-gray-800);
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.button-search-console.active {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
  color: var(--color-white);
}

.button-search-console i {
  color: var(--color-gray-500);
}

.button-search-console:hover {
  background-color: var(--color-priority);
  transition: background-color 0.3s ease-in-out;
}

.button-default::first-letter {
  text-transform: uppercase;
}

.button-search-console.disable {
  background-color: var(--color-gray-100);
  cursor: auto;
}

/* Button end*/
/* SegmentedControl */

.segmented-control {
  display: flex;
  border: 0px solid var(--color-gray-300);
  border-radius: 80px;
  overflow: hidden;
  background-color: var(--color-white);
}

.segment {
  flex: 1;
  padding: 8px 16px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.segment:hover {
  background-color: var(--color-gray-200);
}

.segment.active {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.segment:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.segment:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* SegmentedControl end */

.private_menu li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;
  border-radius: 0 80px 80px 0;
  height: 48px;
  color: var(--color-gray-800);
}

.private_menu i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 40px;
  padding: 0 8px 0 0;
}

.private_menu .Link {
  /* text-decoration: none; */
}

.Link .private_menu-item-active {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.Link .private_menu-item-active p {
  color: var(--color-white);
}

/* Menu end */

/* Private Orders list  */

.private_ordersList_itemPrice {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: 48px;
  box-sizing: border-box;
  margin-left: 16px;
  padding-right: 16px;
}

.private_ordersList_itemPrice_borderBottom {
  border-bottom: 1px var(--color-gray-200) solid;
}

/* End Private Orders list  */

/* Компоненты UI */

.private_profile-mainBox {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  gap: 16px;
  /* padding: 24px 0; */
  /* height: 100vh; */
}

.private_profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-left: var(--widthMenu); */
  justify-content: flex-start;
  /* width: 100%; */
  /* background-color: var(--color-white); */
}

.private_content-box {
  /* display: grid; */
  /* grid-template-columns: repeat(12, 1fr); */
  display: flex;
  flex-direction: column;
  /* grid-gap: 24px; */
  /* max-width: 900px; */
  width: var(--widthContent);
  /* padding: 24px; */
  box-sizing: border-box;
  /* background-color: var(--color-gray-100); */
}

@media (min-width: var(--widthContent)) {
  .private_content-box {
    position: sticky;
    left: var(--widthMenu);
  }
}

/* Компоненты UI end */
.another-element {
  grid-column: 1 / span 6;
  background-color: var(--color-primary);
  /* остальные стили */
}

/* Wiget box */
.private_wiget-big-box {
  /* grid-column: span 12; */

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* background-color: var(--color-gray-100); */
  /* border: 1px solid var(--color-gray-300); */
  /* padding: 16px 0 16px 16px; */
  border-radius: 8px;
  box-sizing: border-box;
}

.private_wiget-small-box {
  grid-column: span 6;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid var(--color-gray-300);
  padding: 16px 0 16px 16px;
  border-radius: 8px;
}

/* Wiget box end*/

/* wigetInput_gallery */

.wigetInput_gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: repeat(4, 1fr); */
  gap: 4px;
  grid-auto-flow: dense;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.wigetInput_gallery-main-img {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 16px;
  border-radius: 4px;
  aspect-ratio: 16/9;
  width: 100%;
  background-color: var(--color-gray-100);
  box-sizing: border-box;
  cursor: pointer;
}

.wigetInput_gallery-img {
  grid-column: span 1;
  grid-row: span 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  border-radius: 4px;
  aspect-ratio: 16/9;
  box-sizing: border-box;
  width: 100%;
}

.wigetInput_gallery-img-header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  text-transform: uppercase;
  color: var(--color-gray-800);
  font-weight: 400;
  font-size: 0.8em;
  width: 100%;
  box-sizing: border-box;
  /* border-bottom: 1px var(--color-gray-200) solid; */
  padding: 16px 16px 8px 0;
}

.wigetInput_gallery-img-description {
  color: var(--color-gray-800);
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1.5;
  padding: 8px 10% 8px 0;
}

.wigetInput_gallery-quantity-photo {
  background-color: var(--color-white);
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: 300;
  font-size: 0.8em;
  color: var(--color-gray-800);
  box-sizing: border-box;
}

/* wigetInput_gallery end */

/* popup */

.popup-menu ul li {
  font-size: 1.4em;
  border-right: 1px var(--color-gray-400) solid;
  padding: 0px 8px;
  color: var(--color-gray-900);
  cursor: pointer;
}

.popup-menu ul li:last-child {
  border: 0;
}

.popup-menu ul li i {
  padding: 8px;
  border-radius: 8px;
}

.popup-menu ul li:hover i {
  background-color: var(--color-gray-100);
}

.popup-menu ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0 8px;
}

.popup-menu-box-left Button {
  margin-right: 8px;
  white-space: nowrap;
}

.popup-menu-box-right Button {
  margin-left: 8px;
  white-space: nowrap;
}

.popup-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--color-gray-100);
  padding: 16px 8px;
}

.popup-menu-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 900px;
  background-color: var(--color-gray-200);
  padding: 8px 10px;
  border-radius: 800px;
  /* box-sizing: border-box; */
}

.popup-menu-box-left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.popup-menu-box-right {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.no-scroll {
  overflow-y: hidden;
  height: 100%;
}

/* Фоновая область на весь экран */

.popup-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  gap: 8px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  z-index: 1000;
  padding: 16px;
  box-sizing: border-box;
}

.popup-overlay-scroll {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
}

.popup-footter {
  border-top: 1px var(--color-gray-200) solid;
}

/* Окно поверх области */
.popup-content {
  /* left: 0;
    right: 0; */
  background-color: var(--color-white);
  /* border: 1px solid #767676; */
  width: var(--widthContent);
  padding-top: 32px;
  /* border-radius: 16px; */
  box-sizing: border-box;
}

.popup-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-bottom: 1px var(--color-gray-300) solid; */
  width: 100%;
  padding: 16px 0;
}

.popup-header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: var(--widthContent);
}

.popup-header-description {
  font-size: 0.8em;
  color: var(--color-gray-600);
  padding-right: 25%;
}

/* popup end */

/* dropzone  */
.dropzone {
  width: 200px;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dropzone:hover {
  border-color: var(--color-primary);
}

.dropzone img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

/* dropzone end */

/* wigetAvatar */

.wigetAvatar_img {
  height: 72px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  border-radius: 80px;
  margin: 8px;
  aspect-ratio: 1 /1;
}

/* wigetAvatar end */

/* item design */
.wigetItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* border-bottom: 1px var(--color-gray-300) solid; */
  cursor: pointer;
  background-color: var(--color-gray-100);
  border-radius: 8px;
  padding: 16px 0;
  box-sizing: border-box;

  /* box-sizing: border-box; */
}

.wigetItem:hover {
  background-color: var(--color-gray-200);
  /* margin-left: -16px; */
  /* padding-left: 16px; */
  transition: background-color 0.3s ease;
  box-sizing: border-box;
}

.wigetItem i {
  padding-right: 16px;
  color: var(--color-gray-600);
}

.wigetItem_finaly {
  border: 0px;
  box-sizing: border-box;
  /* box-sizing: border-box; */
}

.wigetItem_read-subItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0;
}

.wigetItem_open {
  display: flex;
  border: 0;
  box-sizing: border-box;
  /* box-sizing: border-box; */
  /* background-color: var(--color-gray-100);
    margin-left: -16px;
    padding-left: 16px; */
}

.wigetItem_open:hover {
  background-color: var(--color-gray-100);
}

.wigetItem_header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: 16px;
  padding: 4px 0;
  width: 100%;
  box-sizing: border-box;
}

.rentalPoints_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 0 16px;
}

.wigetItem_header .p-light {
  width: 25ch;
}

/* item design end */

/* RentalPoints */
.rentalPoints_icon-container {
  width: 48px;
  height: 48px;
  aspect-ratio: 1/1;
  background-image: url("https://res.cloudinary.com/dv7wszxg2/image/upload/v1685270526/bike-tripster/elements/point_q3f4pk.svg");
  background-position: center;
  background-size: cover;
  /* margin-right: 16px; */
  box-sizing: border-box;
}

.rentalPoints_icon-location {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding-right: 16px;
}

.rentalPoints_content-box {
  /* border-bottom: 1px var(--color-gray-300) solid; */
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  background-color: var(--color-gray-100);
  border-radius: 8px;
  box-sizing: border-box;
}

.rentalPoints_content-box-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 16px 0;
  gap: 16px;
}

.rentalPoints_content-box-right {
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 16px 0;
}

/* RentalPoints end */

/* Inform box item */
.inform-box-inputItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  /* margin-bottom: 24px; */
}

.inform-box-add_button {
  background-color: var(--color-primary);
  padding: 8px 16px;
  border: 0;
  color: var(--color-white);
  border-radius: 80px;
  cursor: pointer;
}

.inform-box * {
  box-sizing: border-box;
}

.inform-box-pick {
  background-color: var(--color-gray-100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 16px;
  border-radius: 8px;
}

.inform-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
}

.inform-box-header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  text-transform: uppercase;
  color: var(--color-gray-800);
  font-weight: 400;
  font-size: 0.8em;
  width: 100%;
  border-bottom: 1px var(--color-gray-200) solid;
  padding: 16px 16px 8px 16px;
  box-sizing: border-box;
}

.inform-box-footer {
  color: var(--color-gray-800);
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1.5;
  padding: 8px 0 24px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
  position: relative;
  cursor: pointer;
}

.inform-box-footer:hover {
  color: var(--color-primary);
}

.inform-box-footer::before {
  content: "";
  background-image: url("https://res.cloudinary.com/dv7wszxg2/image/upload/v1689409539/bike-tripster/swg/circle-info-solid_ug6rig.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 16px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  vertical-align: middle;
}

.inform-box-footer-empty {
  padding: 4px 0;
}

.inform-box-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 16px;
  box-sizing: border-box;
}

.inform-box-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 48px;
  border-bottom: 1px var(--color-gray-200) solid;
  padding: 8px 16px 8px 0;
  box-sizing: border-box;
}

.inform-box-content-cursor {
  cursor: pointer;
}

.inform-box-content-pick {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 48px;
  padding: 8px 16px 8px 0;
}

.inform-box-content-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
}

.inform-box-content-left p {
  font-weight: 400;
}

.inform-box-content-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
}

.inform-box-content-right-params {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  width: 100%;
}

.inform-box-content-right-number-params {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 16px;
  background-color: var(--color-gray-100);
  border-radius: 4px;
  min-height: 34px;
  /* margin: 0 8px; */
  font-size: 0.8em;
  color: var(--color-gray-700);
}

.inform-box-content-limit {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  overflow: hidden;
}

.inform-box-icon-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 24px;
  aspect-ratio: 1/1;
  margin-right: 16px;
  color: var(--color-gray-500);
}

.inform-box-icon-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 48px;
  aspect-ratio: 1/1;
  margin-left: 8px;
  border-radius: 8px;
  background-color: var(--color-gray-100);
  color: var(--color-gray-500);
}

/* Inform box item end*/

/* switcher  */
.switcher-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  font-weight: 600;
  position: relative;
  z-index: 1;
}

.switcher-box p {
  padding-left: 16px;
}

.switcher {
  width: 51px;
  height: 31px;
  border-radius: 15px;
  background-color: var(--color-control-active);
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.switcher:hover {
  transition: background-color 0.3s ease;
  background-color: var(--color-priority);
}

.switcher-checked {
  width: 51px;
  height: 31px;
  border-radius: 15px;
  /* background-color: var(--color-control-active); */
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: var(--color-success);
  z-index: 1;
}

.switcher__handle {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease-in-out;
  z-index: 1;
}

.switcher__handle--checked {
  transform: translateX(20px);
  z-index: 1;
}

/* switcher end */

/* WigetTimetableL */
.wigetTimetablel-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: var(--color-gray-100);
  min-height: 34px;
  padding: 0 16px;
  font-size: 0.8em;
  color: var(--color-gray-700);
  margin-left: 8px;
}

/* WigetTimetableL end*/

/* WigetRate */
.wigetRate-price-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;
  gap: 16px;
  width: 100%;
}

.wigetRate {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 0 16px;
}

.wigetRate-mounth-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 56px;
  height: 100%;
  border-radius: 8px;
  background-color: var(--color-gray-100);
  border: 3px var(--color-gray-100) solid;
  box-sizing: border-box;
  padding: 8px;
  font-size: 0.8em;
}

.wigetRate-mounth-item:hover {
  border: 3px var(--color-primary) solid;
  box-sizing: border-box;
  cursor: pointer;
}

.wigetRate-mounth-noPrice {
  background-color: var(--color-alarm);
  border: 3px var(--color-alarm) solid;
  color: var(--color-white);
}

.wigetRate-currentMonth-point {
  height: 8px;
  aspect-ratio: 1/1;
  background-color: var(--color-white);
  border-radius: 80px;
}

.wigetRate-currentMonth-noPrice {
  background-color: var(--color-primary);
}

.wigetRate-mounth-name {
  font-weight: 600;
}

.wigetRate-currentMonth {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* WigetRate end */

/* WigetBukeDetail */
.wigetBukeDetail-minimum-characteristics {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.wigetBukeDetail-inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.wigetBukeDetail-inputs-bottom {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wigetBukeDetail-content-box {
  border-bottom: 1px var(--color-gray-300) solid;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
}

.wigetBukeDetail-content-box-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding-right: 16px;
}

.wigetBukeDetail-content-box-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  /* padding-right: 16px; */
}

/* WigetBukeDetail end */

/* InputWrite */
.input-write-container {
  position: relative;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-icon-clear {
  position: absolute;
  top: 50%;
  left: 94%;
  transform: translateY(-50%);
  font-size: 1.5em;
  color: var(--color-gray-800);
  transition: top 0.3s, font-size 0.3s, color 0.3s;
  pointer-events: none;
  padding: 0 8px;
}

.input-write-label {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-55%);
  font-size: 1.2em;
  font-weight: 400;
  color: var(--color-gray-800);
  transition: top 0.3s, font-size 0.3s, color 0.3s;
  pointer-events: none;
  background-color: var(--color-white);
  padding: 0 8px;
}

.input-write-label-up {
  top: 0;
  font-size: 0.8em;
  color: var(--color-primary);
}

.input-write-input {
  padding: 8px 8px 8px 20px;
  border: 1px solid var(--color-gray-600);
  border-radius: 4px;
  font-size: 1.2em;
  width: 100%;
  height: 64px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.input-write-input:focus {
  outline: none;
  border-color: var(--color-primary);
  border: 2px var(--color-primary) solid;
}

.input-write-focused .input-write-label {
  color: var(--color-primary);
}

.input-write-focused .input-write-input {
  border-color: var(--color-primary);
}

.input-write-blurred .input-write-label,
.input-write-blurred .input-write-input {
  color: var(--color-gray-800);
  border-color: var(--color-gray-600);
}

.input-clue {
  font-size: 0.8em;
  color: var(--color-gray-800);
  /* margin-bottom: 24px; */
}

/* InputWrite end */

/* WigetImage */

/* .wigetImage-menu-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
    background-color: var(--color-gray-100);
} */

.wigetImage-gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;
  gap: 16px;
}

/* 
.wigetImage-uploadFile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-gray-100);
    width: 100%;
    padding: 8px 24px;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 24px;
} */

.wigetImage-uploadFile-selectedFile {
  background-color: var(--color-white);
  padding: 4px 4px 4px 24px;
  width: auto;
  border-radius: 80px;
  /* margin: 8px 0; */
}

.wigetImage-uploadFile-selectedFile Button {
  margin: 0;
  white-space: nowrap;
}

.wigetImage-img {
  background-color: var(--color-gray-100);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  aspect-ratio: 16 / 9;
  padding: 16px;
  border-radius: 4px;
}

.wigetImage-img-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 8px 0;
}

.wigetImage-img-inform {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  backdrop-filter: blur(8px);
  background-color: #00000070;
  padding: 8px 16px;
  border-radius: 8px;
  color: var(--color-white);
}

.wigetImage-img-inform i {
  margin-right: 8px;
  color: var(--color-white);
}

.wigetImage-img-inform-priority {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  height: 32px;
  width: 32px;
  backdrop-filter: blur(8px);
  background-color: #00000070;
  color: var(--color-white);
}

.wigetImage-img-back {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: var(--color-gray-100);
  border-radius: 8px;
}

.wigetImage-img-back-priority {
  background-color: var(--color-primary);
}

/* WigetImage end */

/* ContextMenu */
.context-menu {
  /* position: relative; */
  /* display: inline-block; */
  position: relative;
  left: 0px;
  top: 0px;
}

.context-menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  font-size: 1.2em;
  /* backdrop-filter: blur(8px);
    background-color: #00000070;
    color: var(--color-white);
    border: none;
    border-radius: 80px; */
  cursor: pointer;
}

.context-menu-button-priority {
  color: var(--color-white);
}

.context-menu-list {
  width: 220px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-100);
  border-radius: 4px;
  box-shadow: 0 2px 4px #0000001a;
}

.context-menu-item {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px var(--color-gray-400) solid; */
  padding: 8px 16px;
  cursor: pointer;
}

.context-menu-item-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.context-menu-item-component i {
  padding: 0 10px;
  font-size: 1.2em;
}

.context-menu-item:hover {
  background-color: var(--color-gray-100);
}

/* ContextMenu end*/

/* Checkbox */
.checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-priority {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-label {
  margin-left: 8px;
  font-size: 14px;
  color: var(--color-gray-900);
  /* color: rgba(0, 0, 0, 0.87); */
}

.checkbox-priority-label {
  margin-left: 8px;
  font-size: 14px;
  color: var(--color-white);
  /* color: rgba(0, 0, 0, 0.87); */
}

.checkbox input {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 2px;
  outline: none;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  position: relative;
}

.checkbox-priority input {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.54);
  border-radius: 2px;
  outline: none;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  position: relative;
}

.checkbox input:checked {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.checkbox-priority input:checked {
  background-color: var(--color-white);
  border-color: var(--color-white);
}

.checkbox input:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-image: url("https://res.cloudinary.com/dv7wszxg2/image/upload/v1688995738/check-solid_nv3rcf.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 20% 100%, 0% 80%); */
  filter: brightness(0) invert(1);
}

.checkbox-priority input:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-image: url("https://res.cloudinary.com/dv7wszxg2/image/upload/v1688995738/check-solid_nv3rcf.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 20% 100%, 0% 80%); */
  filter: brightness(0) invert(0);
}

.checkbox input:focus {
  /* box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.2); */
  border: 2px var(--color-primary) solid;
}

.checkbox-priority input:focus {
  /* box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.2); */
  border: 2px var(--color-white) solid;
}

/* Checkbox end */

/* directory */
.directory {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 8px;
  background-color: var(--color-gray-100);
  border-radius: 8px;
  box-sizing: border-box;
}

/* directory end */

/* TextareaWrite */

.textarea-write-container {
  display: flex;
  flex-direction: column;
  padding: 32px;
  background-color: var(--color-white);
  border-radius: 8px;
  border: 1px solid var(--color-gray-200);
  width: 100%;
  box-sizing: border-box;
  /* margin-bottom: 16px; */
}

.textarea-write-container.focused {
  box-shadow: 0 0 0 2px var(--color-primary);
  border: 1px solid var(--color-white);
}

.textarea-write-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1em;
  color: #888;
  margin-bottom: 32px;
}

.textarea-write-label p {
  padding: 8px 16px;
  background-color: var(--color-gray-100);
  border-radius: 80px;
}

.textarea-write-input {
  height: auto;
  min-height: 100px;
  border: none;
  font-size: 1em;
  color: var(--color-gray-900);
  resize: none;
  overflow: auto;
  background-color: transparent;
  line-height: 1.3;
}

.textarea-write-input:focus {
  outline: none;
}

/* TextareaWrite end */

/* Radio */

.radio-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.radio-container input {
  opacity: 0;
}

.radio-checkmark {
  padding-right: 16px;
  font-size: 1.3em;
}

.radio-checkmark-focus {
  color: var(--color-primary);
}

/* Radio end */

/* send-view */
.send-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* send-view end */

/* WigetСategoriesBikeDetail */

/* WigetСategoriesBikeDetail end */

/* WigetButtonDelete */
.wigetButtonDelete-imform {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 16px 16px;
  background-color: var(--color-white);
  /* margin: 16px 16px 24px 16px; */
  border-radius: 8px;
}

.wigetButtonDelete-imform Button {
  background-color: var(--color-alarm);
}

.wigetButtonDelete-imform p {
  color: var(--color-alarm);
  padding: 0 32px 0 0;
}

/* WigetButtonDelete end */

/* item-menu */
.item-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: calc(100% + 16px); */
  background-color: var(--color-gray-100);
  /* margin-left: -16px; */
  padding: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-gray-200);
}

.item-menu-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--color-gray-200);
  padding: 8px 8px;
  margin: 0 16px;
  box-sizing: border-box;
  border-radius: 80px;
}

/* item-menu end */

/* WigetRentalPointItsBox */
.wigetRentalPointItsBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--color-gray-100);
  /* width: calc(100% - 16px); */
  width: 100%;
  /* margin-left: -16px;
    margin-top: -16px; */
  border-radius: 8px 8px 0 0;
  padding: 16px 16px 16px 16px;
  border-bottom: 1px solid var(--color-gray-300);
  box-sizing: border-box;
}

/* WigetRentalPointItsBox end */

/* datepicker */

/*.react-datepicker-wrapper {
    width: 100%;
}

.datepicker-content {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;

}

.datepicker-city-box {
    grid-column: span 8;
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;

}

.datepicker-city {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 2px 8px;
    height: 32px;
    color: var(--color-gray-800);
    cursor: pointer;
}

.datepicker-city.selected {
    font-weight: bold;
    color: var(--color-black);
}

.datepicker-country-box {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-flow: dense;
    width: var(--widthContent);
    gap: 16px;
}

.datepicker-country {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2px 8px;
    color: var(--color-gray-900);
   }

.datepicker-country.selected {
    font-weight: bold;
    color: var(--color-black);
}

.react-datepicker {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;
    border: 0;
    border-radius: 0;
    border-bottom: 0;

}

.react-datepicker__month-container {
    width: 100%;
  
}

.react-datepicker__header {
    width: 100%;
    background-color: var(--color-white);
    border-color: var(--color-white);
    margin-bottom: 16px;
}

.react-datepicker__day--keyboard-selected {
    border-radius: 8px;
    background-color: var(--color-white);
    color: var(--color-black);
}

.react-datepicker__day-names,
.react-datepicker__week {
    display: flex;
}

.react-datepicker__day-name,
.react-datepicker__day {
    flex: 1;
    text-align: center;
}

.react-datepicker__day,
.react-datepicker__month-text {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    aspect-ratio: 1/1;
}

.react-datepicker__day:hover {
    background-color: #007bff20;
    color: var(--color-black);
}

/* Заголовки календаря */
.react-datepicker__current-month {
  font-size: 1rem;
  color: var(--color-gray-900);
  font-weight: 500;
  padding-bottom: 32px;
}

/* для дат в выбираемом диапазоне */
.react-datepicker__day--in-selecting-range {
  background-color: #007bff20 !important;
  color: var(--color-black) !important;
  /* transition: background-color 0.5s ease-in-out; */
}

/* для выбранных дат */
.react-datepicker__day--selected {
  background-color: var(--color-primary);
  color: var(--color-white);
  /* transition: background-color 0.5s ease-in-out; */
}

.react-datepicker__day--selected:hover {
  background-color: #007bff20 !important;
  /* transition: background-color 0.5s ease-in-out; */
}

/* для дат в выбранном диапазоне */
.react-datepicker__day--in-range {
  background-color: var(--color-gray-100);
  color: var(--color-gray-900);
}

/* для дат в выбранном диапазоне */
.react-datepicker__day--in-range:first-of-type,
.react-datepicker__day--in-range:last-of-type {
  border-radius: 8px;
}

.react-datepicker__month .react-datepicker__day--outside-month {
  background-color: transparent !important;
  color: transparent;
  border: none;
}

.datepicker-box.block {
  height: 0px;
  overflow: hidden;
}

.react-datepicker__navigation-icon::before {
  border-color: var(--color-gray-900);
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: "";
  display: block;
  height: 12px;
  width: 12px;
  position: absolute;
  top: 6px;
}

.react-datepicker__navigation {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: -32px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background-color: none;
  text-indent: -999em;
  overflow: hidden;
  margin-top: 32px;
}

.react-datepicker__navigation:hover {
  background-color: var(--color-gray-100);
}

/* datepicker end */

/* searchCity */

.searchCity-box {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.searchCity-box.open {
  height: 700px;
  overflow: hidden;
}

.searchCity-box.block {
  height: 0px;
  overflow: hidden;
}

/* searchCity end */

/* searchCountry */

.searchCountry-box {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.searchCountry-box.block {
  height: 0px;
  overflow: hidden;
}

/* searchCountry end */

.wigetStories-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.wigetStories-content {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  /* flex-direction: row;
    align-items: center;
    justify-content: center; */
  min-width: var(--widthContent);
  box-sizing: border-box;
}

.wigetStories-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  border-radius: 24px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

/* WigetStories end */

/* WigetSearch */

.wigetSearch-horisontal-flag {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wigetSearch-horisontal-locationName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.wigetSearch-horisontal-quantity {
  display: flex;
  flex: 1;
  grid-column: 1;
  background-color: var(--color-gray-100);
  border-radius: 16px;
  padding: 8px;
  box-sizing: border-box;
  transition: background-color 0.5s ease;
}

.wigetSearch-horisontal-quantity:hover {
  background-color: var(--color-priority);
  transition: background-color 0.5s ease;
}

.wigetSearch-horisontal-quantity .quantityControl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.wigetSearch-horisontal-quantity .quantityControl-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 32px;
  aspect-ratio: 1/1;
  background-color: var(--color-white);
  border-radius: 4px;
}

.wigetSearch-horisontal-quantity .quantity-content {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.wigetSearch-horisontal-quantity .quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 4rem;
  line-height: 0;
  font-weight: 600;
  color: var(--color-gray-900);
}

.wigetSearch-horisontal-location {
  grid-column: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: var(--color-gray-100);
  border-radius: 16px;
  padding: 16px;
  transition: background-color 0.5s ease;
  cursor: pointer;
}

.wigetSearch-horisontal-title {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: var(--widthContent);
  box-sizing: border-box;
  font-size: 3rem;
  font-weight: 300;
}

/* .wigetSearch-horisontal-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    height: 100%;
    border-radius: 24px;
    box-sizing: border-box;
    aspect-ratio: 1/1;
} */

.wigetSearch-horisontal-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: var(--color-gray-100);
  height: 56px;
  width: 100%;
  border-radius: 16px;
  box-sizing: border-box;
}

.wigetSearch-horisontal-controls {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  flex: 1;
  gap: 8px;
}

.wigetSearch-horisontal-location:hover {
  background-color: var(--color-priority);
  transition: background-color 0.5s ease;
}

.wigetSearch-horisontal-daysBox {
  grid-column: 2;
  grid-row: span 2;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--color-gray-100);
  /* background-color: var(--color-white); */
  padding: 8px;
  border-radius: 16px;
  transition: background-color 0.5s ease;
  cursor: pointer;
}

.wigetSearch-horisontal-daysBox:hover {
  background-color: var(--color-priority);
  transition: background-color 0.5s ease;
}

.wigetSearch-horisontal-daysBox:hover .day,
.wigetSearch-horisontal-daysBox:hover .separator,
.wigetSearch-horisontal-daysBox:hover .separatorVertical {
  background-color: var(--color-priority);
  transition: background-color 0.5s ease;
}

.wigetSearch-horisontal-daysBox .separator {
  height: 4px;
  border-radius: 80px;
  background-color: var(--color-gray-100);
  /* border-top: 1px var(--color-gray-200) solid; */
  width: 100%;
  transition: background-color 0.5s ease;
}

.wigetSearch-horisontal-daysBox .separatorVertical {
  height: 100%;
  border-radius: 80px;
  background-color: var(--color-gray-100);
  width: 8px;
  transition: background-color 0.5s ease;
}

.wigetSearch-horisontal-daysBoxBg {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 8px;
  gap: 8px;
}

.wigetSearch-horisontal-daysBoxBg-horisontal {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 8px;
  gap: 8px;
}

.wigetSearch-horisontal-startDay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.wigetSearch-horisontal-startDay .day {
  display: flex;
  justify-content: center;
  background-color: var(--color-gray-100);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  transition: background-color 0.5s ease;
}

.wigetSearch-horisontal-startDay .dayMonth {
  font-size: 4rem;
  font-weight: 700;
  color: var(--color-gray-900);
}

.wigetSearch-horisontal-rightContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 8px;
}

.wigetSearch-horisontal-search-map {
  margin-top: 150px;
  height: 400px;
  /* background-color: var(--color-white); */
  width: var(--widthContent);
  border-radius: 24px;
  overflow: hidden;
  padding: 8px;
  box-sizing: border-box;
}

.wigetSearch-horisontal-search-map-wiget {
  height: 100%;
  width: 30%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  backdrop-filter: blur(10px);
}

.wigetSearch-horisontal-search-categories {
  width: var(--widthContent);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 24px 0;
}

.button-horisontal-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  height: 56px;
  width: 100%;
  /* padding: 0 24px; */
  background: var(--gradient-primary);
  border-radius: 16px;
  font-size: 1.3rem;
  font-weight: 300;
}

.wigetSearch-horisontal-search-categories .categories-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 48px;
  background-color: var(--color-control-active);
  padding: 0 24px;
  border-radius: 80px;
}

/* .wigetSearch-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-white);
    box-sizing: border-box;
    padding: 24px;
    border-radius: 32px;
} */

.wigetSearch-search-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  box-sizing: border-box;
}

/* WigetSearch end*/

/* WigetNews */

.wigetNews-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.wigetNews-content {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-flow: dense;
  gap: 32px;
  max-width: var(--widthContent);
  width: 100%;
  box-sizing: border-box;
}

.wigetNews-content .header {
  grid-column: 1 / span 11;
  font-size: 3rem;
  font-weight: 300;
  /* padding: 48px 0 48px 0; */
}

.wigetNews-6 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  border-radius: 24px;
  grid-column: span 6;
  grid-row: span 1;
  background-color: var(--color-black);
  gap: 8;
  box-sizing: border-box;
  color: var(--color-gray-200);
  aspect-ratio: 1/1;
}

.wigetNews-6 h2,
.wigetNews-12 h2 {
  color: var(--color-gray-200);
  font-size: 2rem;
  font-weight: 200;
}

.wigetNews-12 .text-container-title,
.wigetNews-6 .text-container-title {
  display: flex;
  flex-direction: column;
  padding: 48px;
  border-radius: 16px;
  gap: 8px;
}

.wigetStories-img .text-container-description {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 32px 48px;
  border-radius: 16px;
  backdrop-filter: blur(10px);
  color: var(--color-white);
  gap: 8px;
}

.text-container-description .nexPoint-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  opacity: 0.5;
}

.text-container-description .nexPoint {
  height: 4px;
  aspect-ratio: 1/1;
  background-color: var(--color-white);
  border-radius: 80px;
  /* opacity: 0.2; */
}

.wigetStories-img h3 {
  color: var(--color-white);
  font-weight: 300;
}

.wigetStories-img .text-container-title {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 16px;
  gap: 8px;
  color: var(--color-white);
}

.wigetNews-6 .text-container-description {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 32px 48px;
  border-radius: 16px;
  backdrop-filter: blur(10px);
}

.wigetNews-12 .text-container-description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.5);
  width: calc(50% - 24px);
  padding: 48px;
  border-radius: 16px;
  backdrop-filter: blur(10px);
  height: 100%;
  box-sizing: border-box;
}

.text-container-description .text {
  max-height: calc(1.5 * 3em);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.5;
  box-sizing: border-box;
}

.wigetNews-6 .text {
  max-height: calc(1.5 * 3em);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.5;
  box-sizing: border-box;
}

.wigetNews-12 .text {
  max-height: calc(1.5 * 10em);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.5;
  box-sizing: border-box;
}

.wigetNews-12 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  border-radius: 24px;
  grid-column: span 12;
  grid-row: span 1;
  background-color: var(--color-black);
  gap: 8;
  box-sizing: border-box;
  color: var(--color-gray-200);
}

.wigetNews-mainCard {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  border-radius: 16px;
  grid-column: span 8;
  grid-row: span 1;
  background-color: var(--color-gray-100);
}

.wigetNews-card {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  border-radius: 16px;
  grid-column: span 4;
  grid-row: span 1;
  background-color: var(--color-gray-100);
}

/* WigetNews end */

/* wigetBrend */

.wigetBrend-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.wigetBrend-content {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
  /* flex-direction: row;
    align-items: center;
    justify-content: center; */
  max-width: var(--widthContent);
  box-sizing: border-box;
}

.wigetBrend-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  border-radius: 16px;
  background-color: var(--color-gray-100);
}

/* wigetBrend end */

/* WigetBikes */

.wigetBikes-product-card {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  border-radius: 24px;
  overflow: hidden;
  /* padding: 16px 0; */
}

.wigetBikes-content-grid {
  grid-column: span 2;
  /* border-radius: 24px; */
  overflow: hidden;
}

.wigetBikes-content-grid-left {
  grid-column: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 32px;
  border-radius: 24px;
  /* overflow: hidden; */
}

.wigetBikes-content-grid-left-content {
  width: 100%;
  /* aspect-ratio: 16/9; */
  background-color: var(--color-white);
  /* border-radius: 32px; */
  /* overflow: hidden; */
  border-radius: 24px;
  box-sizing: border-box;
}

.wigetBikes-content-grid-right {
  grid-column: 2;
  display: flex;
  flex-direction: column;
  gap: 32px;
  box-sizing: border-box;
  overflow: hidden;
  /* border-radius: 24px; */
}

.wigetBikes-content-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  box-sizing: border-box;
}

.wigetBikes-img {
  grid-column: 1;
  height: 100px;
  aspect-ratio: 16/9;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  box-sizing: border-box;
}

/* CSS-переход */
.element {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.wigetBikes-img:hover .element {
  transform: scale(1);
  opacity: 1;
}

/* WigetBikes end */

/* wigetProductList */

/* .wigetProductList-reservation {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 0;
} */

.wigetProductList-order-detail-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.wigetProductList-order-detail-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 30px;
  background-color: var(--color-gray-100);
  padding: 16px;
  font-size: 1rem;
}

.wigetProductList-order-detail-item-date {
  height: 4px;
  width: 50%;
  background-color: var(--color-gray-500);
  border-radius: 80px;
}

.wigetProductList-grid-numberOfDays {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000025;
  /* border-radius: 40px; */
  backdrop-filter: blur(24px);
  font-size: 4em;
  font-weight: 400;
  color: var(--color-gray-100);
  border-radius: 16px;
  /* aspect-ratio: 1/1; */
}

.wigetProductList-grid-brendIcon {
  height: 100px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000025;
  border-radius: 16px;
  backdrop-filter: blur(24px);
}

.wigetProductList-grid-screenshot {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  /* background-color: var(--color-gray-100); */
}

.wigetProductList-price-contaiber {
  background-color: var(--color-white);
  border-radius: 24px;
  padding: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  /* gap: 24px; */
  min-height: 990px;
  height: calc(100vh - 132px);
  overflow: hidden;
  margin: 0 0 0 0;
  transition: height 0.3s ease-in, margin 0.3s ease-in;
  gap: 8px;
}

.wigetProductList-price-contaiber.transform {
  margin: 80px 0 0 0;
  height: calc(100vh - 132px);
  transition: height 0.3s ease-in, margin 0.3s ease-in;
  box-sizing: border-box;
  min-height: 990px;
}

.wigetBikes-product-card-info {
  display: grid;
  grid-template-columns: 1fr 300px;
  width: "100%";
  padding: 16px 24px 24px 24px;
}

.wigetBikes-product-characteristic {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  justify-content: space-between;
  padding: 2px 0;
  font-size: 0.8rem;
  list-style: none;
  color: var(--color-gray-700);
  padding: 24px 0 0 24px;
}

/* .wigetProductList-grid-bikeinfo {
    grid-column: 1 / span 9;
    grid-row: 10 / span 3;
    background-color: var(--color-white);
    border-radius: 40px;
    padding: 24px;
} */

.wigetProductList-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--color-gray-700);
}

.wigetProductList-price .discount {
  text-decoration: line-through;
}

.wigetProductList-price-item {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
  gap: 16px;
}

.wigetProductList-price-items {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* wigetProductList end */

/* WigetSlider */

.slider-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.slider {
  display: flex;
  transition: transform 0.3s ease;
  box-sizing: border-box;
}

.slide {
  flex-shrink: 0;
  background-color: var(--color-white);
}

.slider-button-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.prev-button,
.next-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 56px;
  background-color: var(--color-gray-100);
  color: #fff;
  border: none;
  cursor: pointer;
  z-index: 1000;
  aspect-ratio: 1/1;
  border-radius: 80px;
}

/* .prev-button {
    left: 0;
} */

/* .next-button {
    right: 0;
} */

/* WigetSlider end */

/* WigetGallerySlider */

.gallery-slider-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 500px; */
}

.gallery-slider-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gallery-slider {
  display: flex;
  transition: transform 0.3s ease;
}

.gallery-slide {
  flex-grow: 0;
  flex-shrink: 0;
  /* aspect-ratio: 16/9; */
  /* height: calc(100vh - 400px); */
  /* height: 100%; */
  border-radius: 16px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}

/* .gallery-slide-oneImage {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 1200px;
    aspect-ratio: 16/9;
    border-radius: 16px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
} */

/* .gallery-slide:last-child {
    margin-right: 0;
} */

button:hover {
  text-decoration: none;
}

/* WigetGallerySlider end */

/* WigetFilter */

.wigetFilter-contant {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* WigetFilter end */

.wigetFilter-button-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  color: var(--color-gray-800);
}

.filter-button {
  width: 100%;
  border: 0px;
  /* background-color: var(--color-gray-300); */
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.filter-button.active {
  cursor: auto;
}

@keyframes wigetFilter-radio-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.wigetFilter-radio {
  /* animation: wigetFilter-radio-animation 0.5s ease-in-out; */
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-control-active);
  border-radius: 80px;
  transition: background-color 0.3s ease-in-out;
}

.filter-button:hover .wigetFilter-radio {
  background-color: var(--color-priority);
  transition: background-color 0.3s ease-in-out;
}

.filter-button-active {
  height: 12px;
  width: 12px;
  background-color: var(--color-primary);
  border-radius: 80px;
}

/* WigetFilter end */

.noContent {
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}

/* Calendar */

/* Calendar end */

.content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-body {
  margin-top: 80px;
  display: grid;
  grid-template-columns: 330px 1fr;
  width: var(--widthContent);
  /* background-color: var(--color-gray-100); */
  gap: 32px;
}

.content-body-news {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: var(--widthContent);
  /* background-color: var(--color-gray-100); */
  gap: 32px;
}

/* ImageWithPreloader */

.image-container {
  position: relative;
  width: 300px;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

img {
  width: 100%;
}

/* ImageWithPreloader end */

/* WigetProdictCard */

.wigetProdictCard-price-payToRental {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--color-gray-100);
  padding: 16px;
  border-radius: 16px;
  transition: background-color 0.5s ease;
}

.wigetProdictCard-price-payToRental:hover {
  background-color: var(--color-priority);
  transition: background-color 0.5s ease;
}

.wigetProdictCard-price-payToRental li {
  /* padding: 0 16px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  border-bottom: 1px var(--color-gray-300) solid;
}

.wigetProdictCard-price-payToRental i {
  color: var(--color-gray-900);
  font-size: 0.8rem;
}

.wigetProdictCard-price-payToRental1 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--color-gray-100);
  padding: 16px;
  border-radius: 16px;
  position: static;
}

.wigetProdictCard-lineContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  /* flex: 0; */
}

.wigetProdictCard-schedule-holiday {
  display: flex;
  align-items: center;
  background-color: var(--color-alarm);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  color: var(--color-white);
}

.wigetProdictCard-schedule-day {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.wigetProdictCard-schedule-day p {
  background-color: var(--color-white);
  /* padding: 4px; */
  /* font-size: 0.8rem; */
  color: var(--color-gray-800);
}

.wigetProdictCard-schedule-time {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.wigetProdictCard-schedule-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wigetProdictCard-mapBoxContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;
  background-color: var(--color-white);
  border-radius: 24px;
  overflow: hidden;
  flex: 1;
}

.wigetProdictCard-schedule {
  grid-column: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 8px;
}

.wigetProdictCard-map {
  grid-column: 2;
  display: flex;
  flex: 1;
}

.wigetProdictCard-payToRental {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: 400;
  flex: 1;
}

.wigetProdictCard-in-box {
  padding: 24px;
  box-sizing: border-box;
}

.wigetProdictCard-in-box p {
  line-height: 1.5;
}

.wigetProdictCard-in-box h5 {
  padding-bottom: 8px;
}

.wigetProdictCard-characteristics {
  background-color: var(--color-gray-100);
  padding: 4px 10px;
  border-radius: 80px;
  font-size: 0.8rem;
  color: var(--color-gray-800);
}

.wigetProdictCard-characteristics-bottom {
  padding: 4px 0;
  border-radius: 80px;
  font-size: 0.8rem;
  color: var(--color-gray-800);
  line-height: 1.5;
}

.wigetProdictCard-price-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  flex: 1;
}

.wigetProdictCard-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.wigetProdictCard-bike-info {
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding-top: 8px;
}

.wigetProdictCard-bike-info p {
  font-size: 0.8rem;
  color: var(--color-gray-800);
  background-color: var(--color-gray-100);
  padding: 8px 16px;
  border-radius: 4px;
}

.wigetProdictCard-listPrice {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-around;
  gap: 4px;
}

.wigetProdictCard-listPrice p {
  font-size: 0.8rem;
  color: var(--color-gray-800);
}

/* .wigetProdictCard-listPrice-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
} */

.wigetProdictCard-listPrice-date.selectedDate {
  font-weight: 600;
  color: var(--color-black);
}

.wigetProdictCard-listPrice-date.dateNow {
  height: 8px;
  aspect-ratio: 1/1;
  border-radius: 80px;
  background-color: var(--color-success);
  box-sizing: border-box;
}

.wigetProdictCard-listPrice-date {
  padding-top: 4px;
}

.wigetProdictCard-inform {
  display: flex;
  flex-direction: column;
  /* background-color: var(--color-gray-100); */
  /* color: var(--color-gray-800); */
  /* padding: 40px 16px; */
  gap: 8px;
}

.wigetProdictCard-main-container {
  min-width: var(--widthContent);
  display: grid;
  grid-template-columns: 330px 1fr;
  /* Первый столбец 400px, второй занимает оставшееся пространство */
  width: 100%;
  gap: 64px;
  background-color: var(--color-white);
  box-sizing: border-box;
  padding: 0 64px;
}

.wigetProdictCard-price-bike {
  grid-column: 1;
}

.wigetProdictCard-content-bike {
  grid-column: 2;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  gap: 16px;
}

/* .wigetProdictCard-price-bike-sticky {
    position: sticky; */
/* background-color: var(--color-white); */
/* border-radius: 24px; */
/* top: 0; */
/* padding: 24px; */
/* } */

.animated-block {
  position: fixed;
  top: 0;
  width: 100%;
  transform: translateY(-50px);
  transition: transform 0.5s;
  background-color: var(--color-white);
}

.animated-block.visible {
  position: fixed;
  transform: translateY(50px);
  transition: transform 0.5s;
  transform: translateY(0);
}

/* WigetProdictCard end */

/* WigetCollapseComponent */

.wigetCollapseComponent-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 1px var(--color-gray-100) solid;
  padding: 16px 0;
}

.wigetCollapseComponent-children {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
}

.wigetCollapseComponent-collapse {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 0.3s ease-out;
  /* padding: 0 0 0 0px; */
  box-sizing: border-box;
  width: 100%;

  /* font-size: 0.8rem; */
  /* color: var(--color-gray-700); */
  line-height: 1.3;
}

.wigetCollapseComponent-collapse.open {
  grid-template-rows: 1fr;
  /* padding-bottom: 16px; */
}

.fa-angle-down {
  color: var(--color-control-active-icon);
  padding: 0 8px;
  transition: transform 0.3s ease;
  cursor: pointer;
  /* Добавляем плавную анимацию */
}

.fa-angle-down.rotate {
  transform: rotate(180deg);
  /* Вращаем на 180 градусов */
}

/* WigetCollapseComponent end */

/* WigetImageCarousel */

/* WigetImageCarousel end */

/* WigetPriceRangeFilter */

.horizontal-slider {
  width: 100%;
  height: 25px;
}

.thumb {
  min-width: 25px;
  height: 25px;
  background-color: var(--color-primary);
  /* border: 1px solid #ccc; */
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  border-radius: 80px;
  color: var(--color-white);
  font-size: 0.8rem;
}

.track {
  top: 12px;
  bottom: 0;
  background: var(--color-control-active);
  height: 3px;
}

.price-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.price-inputs input {
  width: 100%;
  height: 32px;
  padding: 8px;
  border-radius: 8px;
  border: 0;
  background-color: var(--color-control-active);
  font-size: 1rem;
  color: var(--color-gray-800);
  transition: background-color 0.3s ease-in-out;
}

.price-inputs input:hover {
  background-color: var(--color-priority);
  transition: background-color 0.3s ease-in-out;
}

.price-inputs input:focus {
  outline: none;
}

.price-range-filter {
  width: 100%;
  /* padding: 24px; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.price-range-filter-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.price-range-filter-clear {
  animation: fadeIn 0.5s ease-in-out;
  color: var(--color-primary);
  cursor: pointer;
}

.price-range-filter-clear:hover {
  color: var(--color-primary);
  text-decoration: underline;
}

/* WigetPriceRangeFilter end */

/* WigetFilterCategory */

.wigetFilter-container {
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* WigetFilterCategory end */

/* WigetMapSearch */

.wigetMapSearchMax {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* gap: 32px; */
}

.wigetMapSearchMax .popularDestinations {
  width: var(--widthContent);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 32px;
}

.wigetMapSearchMax .popularDestination {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  background-color: var(--color-white);
  border-radius: 24px;
  padding: 24px;
  box-sizing: border-box;
}

.wigetMapSearchMax .location {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.wigetMapSearchMax-map-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-column: span 2;
  padding: 8px;
  /* height: 333px; */
  /* border-radius: 16px; */
  box-sizing: border-box;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
}

.wigetMapSearchMax-box {
  padding: 8px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: dense;
  width: var(--widthContent);
  /* background-color: var(--color-white); */
  box-sizing: border-box;
  border-radius: 24px;
  overflow: hidden;
  gap: 8px;
}

.wigetSearch-horisontal-mapBox {
  grid-column: span 2;
  height: 100%;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex: 1;
}

.wigetMapSearchMax-map-pointFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #0000004d;
  padding: 6px 6px 6px 24px;
  border-radius: 80px;
  gap: 8px;
  font-size: 0.8rem;
  color: var(--color-white);
  backdrop-filter: blur(10px);
}

.wigetMapSearchMax-map-pointFilter.more {
  padding: 4px;
  height: 40px;
}

.wigetMapSearchMax-map-pointFilter.zoom_out_map {
  padding: 4px;
  height: 40px;
  aspect-ratio: 1/1;
  box-sizing: border-box;
}

.wigetMapSearchMax-map-pointFilter.add_circle {
  padding: 4px;
  height: 40px;
  aspect-ratio: 1/1;
  box-sizing: border-box;
}

.wigetMapSearchMax-map-pointFilter .material-symbols-outlined {
  font-size: 32px;
  color: var(--color-white);
}

.wigetMapSearchMax-map-pointsBox {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.wigetMapSearchMax-map-pointBox {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  gap: 8px;
}

.wigetMapSearchMax-map-metric {
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  height: 100%;
  aspect-ratio: 1/1;
}

.wigetMapSearchMax-map-wiget {
}

.wigetMapSearch-map-content {
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  aspect-ratio: 16/9;
  padding: 8px;
  box-sizing: border-box;
  gap: 8px;
}

.wigetMapSearch-map-metric {
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.wigetMapSearch-map-wiget {
  aspect-ratio: 1/2;
  background-color: #ffffff29;
  border-radius: 16px;
  padding: 8px;
  /* height: 100%; */
  width: 33%;
  backdrop-filter: blur(10px);
  font-weight: 500;
  color: var(--color-white);
  font-size: 1em;
}

/* WigetMapSearch end */

/* map */

.mapRentalPoint {
  height: 24px;
  aspect-ratio: 1/1;
  background-color: var(--color-white);
  border: 2px var(--color-primary) solid;
  border-radius: 80px;
  box-shadow: 0px 0px 0px 2px var(--color-white);
}

/* map end */

/* UIHome */

.uIHome-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 32px; */
  width: 100%;
}

.uIHome-content {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 32px;
  width: var(--widthContent);
}

.uIHome-content h2 {
  font-size: 3em;
  font-weight: 400;
  text-align: center;
  width: 100%;
  padding-top: 40px;
}

.uIHome-content-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex: 1;
  gap: 32px;
  width: var(--widthContent);
}

.uIHome-content-2-item-imgBg {
  grid-column: span 2;
}

.uIHome-content-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(2, 1fr); */
  flex: 1;
  gap: 32px;
  width: var(--widthContent);
}

.uIHome-content-3-item {
  width: 100%;
  background-color: var(--color-white);
  border-radius: 32px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.uIHome-content-3-img {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  height: 300px;
  background-color: var(--color-gray-200);
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 32px;
}

.uIHome-content-3-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  gap: 8px;
  text-align: center;
}

.uIHome-content-3-content h3 {
  font-weight: 500;
}

.uIHome-content-3-content p {
  line-height: 1.3em;
}

.uIHome-content-3-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  margin: 24px 0 0 24px;
  width: 48px;
  height: 48px;
  border-radius: 80px;
  background-color: var(--color-gray-100);
}

.uIHome-content-3-icon-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px;
  gap: 16px;
}

.uIHome-content-3-item-imgBg {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: var(--color-white);
  width: 100%;
  border-radius: 32px;
  overflow: hidden;
  padding: 8px;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.uIHome-content-3-content-imgBg {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  /* text-align: center; */
  box-sizing: border-box;
  line-height: 1.3em;
  /* color: var(--color-white); */
}

.uIHome-content-3-content-imgBg h3 {
  font-weight: 500;
  color: var(--color-white);
}

.uIHome-content-3-button {
  border: 0;
  border-radius: 80px;
  height: 48px;
  aspect-ratio: 1/1;
  background: var(--gradient-primary);
  color: var(--color-white);
}

.uIHome-content-lineWiget {
  width: 100%;
  /* height: 56px; */
  border-radius: 80px;
  background-color: var(--color-gray-200);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 8px;
  /* background: linear-gradient(to right, #ffffff, #9F22E6); */
}

.country-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--color-white);
  /* background-color: rgba(255, 255, 255, 0.5); */
  /* backdrop-filter: blur(10px); */
  /* padding: 4px 16px 4px 4px; */
  border-radius: 80px;
}

.container-flag {
  position: relative;
  width: 32px;
  height: 32px;
  margin-right: 72px;
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  width: 50px;
  height: 50px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  border: 3px var(--color-gray-100) solid;
  box-sizing: border-box;
}

.overlay1 {
  background-color: var(--color-white);
  z-index: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.overlay2 {
  background-color: var(--color-white);
  z-index: 2;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  left: 30px;
}

.overlay3 {
  background-color: var(--color-white);
  z-index: 3;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  left: 60px;
}

.uIHome-content-lineWiget-icon {
  height: 50px;
  width: 50px;
  aspect-ratio: 1/1;
  background-color: var(--color-white);
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

/* .material-symbols-outlined {
    font-size: 2rem;
} */

/* UIHome end */

.averageDiscount {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-success);
  padding: 4px 10px;
  font-size: 0.8em;
  border-radius: 80px;
  color: var(--color-white);
  background: linear-gradient(45deg, #fb5085 0%, #c740ca 30%, #3478ff 100%);
}

/* Стили для контейнера Autocomplete, включая выпадающий список */
.pac-container-query {
  background-color: var(--color-white);
  /* Цвет фона */
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
  /* Тень */
  border-radius: 4px;
  /* Скругленные углы */
}

/* Стили для отдельных элементов в выпадающем списке */
.pac-item-query {
  padding: 8px;
  /* Внутренний отступ */
  cursor: pointer;
  height: 100%;
  padding: 0 8px 0 0;
  font-size: 1rem;
  /* Изменение курсора при наведении */
}

/* Стили для иконок, связанных с элементами списка */
.pac-icon-query {
  margin-right: 8px;
  /* Отступ справа от иконки */
}

/* Стили для плейсхолдера в поле ввода */
.pac-placeholder-query {
  color: var(--color-gray-100);
  /* Цвет текста плейсхолдера */
}

/* Стили для выбранного элемента в выпадающем списке */
.pac-item-selected {
  background-color: var(--color-gray-200);
  /* Цвет фона выбранного элемента */
  font-weight: bold;
  /* Жирный шрифт для выделения */
}

.pac-container {
  background-color: var(--color-white);
  border-radius: 0 0 4px 4px;
  border-top: 1px solid var(--color-primary);
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}

.pac-item {
  padding: 8px;
  cursor: pointer;
  height: 32px;
}

.pac-item:hover {
  background-color: var(--color-gray-100);
}

/* ------------ */

@property --p {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

.pie {
  --p: 20;
  --b: 22px;
  --c: darkred;
  --w: 150px;

  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 5px;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
}

.pie:before,
.pie:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.pie:before {
  inset: 0;
  background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b)
      var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}

.pie:after {
  inset: calc(50% - var(--b) / 2);
  background: var(--c);
  transform: rotate(calc(var(--p) * 3.6deg))
    translateY(calc(50% - var(--w) / 2));
}

.animate {
  animation: p 1s 0.5s both;
}

.no-round:before {
  background-size: 0 0, auto;
}

.no-round:after {
  content: none;
}

@keyframes p {
  from {
    --p: 0;
  }
}

@keyframes ldio-u90d8aclv3 {
  0% {
    transform: translate(6px, 40px) scale(0);
  }

  25% {
    transform: translate(6px, 40px) scale(0);
  }

  50% {
    transform: translate(6px, 40px) scale(1);
  }

  75% {
    transform: translate(40px, 40px) scale(1);
  }

  100% {
    transform: translate(74px, 40px) scale(1);
  }
}

@keyframes ldio-u90d8aclv3-r {
  0% {
    transform: translate(74px, 40px) scale(1);
  }

  100% {
    transform: translate(74px, 40px) scale(0);
  }
}

@keyframes ldio-u90d8aclv3-c {
  0% {
    background: var(--color-gray-500);
  }

  25% {
    background: var(--color-gray-500);
  }

  50% {
    background: var(--color-gray-500);
  }

  75% {
    background: var(--color-gray-500);
  }

  100% {
    background: var(--color-gray-500);
  }
}

.ldio-u90d8aclv3 div {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(40px, 40px) scale(1);
  background: var(--color-gray-500);
  animation: ldio-u90d8aclv3 1s infinite cubic-bezier(0, 0.5, 0.5, 1);
}

.ldio-u90d8aclv3 div:nth-child(1) {
  background: var(--color-gray-500);
  transform: translate(74px, 40px) scale(1);
  animation: ldio-u90d8aclv3-r 0.25s infinite cubic-bezier(0, 0.5, 0.5, 1),
    ldio-u90d8aclv3-c 1s infinite step-start;
}

.ldio-u90d8aclv3 div:nth-child(2) {
  animation-delay: -0.25s;
  background: var(--color-gray-500);
}

.ldio-u90d8aclv3 div:nth-child(3) {
  animation-delay: -0.5s;
  background: var(--color-gray-500);
}

.ldio-u90d8aclv3 div:nth-child(4) {
  animation-delay: -0.75s;
  background: var(--color-gray-500);
}

.ldio-u90d8aclv3 div:nth-child(5) {
  animation-delay: -1s;
  background: var(--color-gray-500);
}

.loadingio-spinner-ellipsis-yzgrhszxc4p {
  width: 31px;
  height: 31px;
  display: inline-block;
  overflow: hidden;
  background: rgba(NaN, NaN, NaN, 0);
}

.ldio-u90d8aclv3 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.31);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-u90d8aclv3 div {
  box-sizing: content-box;
}

/* logo */
.verySad {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* popup */
.popupBg {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  z-index: 100;
}

.popupWindowContent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: var(--widthContent);
  background-color: var(--color-white);
  box-sizing: border-box;
  border-radius: 24px;
  position: absolute;
  z-index: 101;
}

.popup-button-bur {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

/* GPT Helper */
.chatWindowDialog {
  position: fixed;
  width: 0;
  height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.9);
  right: 24px;
  bottom: 24px;
  border-radius: 16px;
  backdrop-filter: blur(64px);
  overflow: hidden;
  gap: 8px;
  padding: 8px;
}

.startChat {
  animation: closeChat 1s ease-in-out;
  width: 500px;
  height: 72px;
  opacity: 1;
  right: 24px;
  bottom: 24px;
}

@keyframes openChat {
  0% {
    width: 500px;
    height: 72px;
    opacity: 1;
    right: 24px;
    bottom: 24px;
  }

  40% {
    width: 500px;
    height: 72px;
    opacity: 1;
    right: 24px;
    bottom: 24px;
  }

  80% {
    width: 500px;
    height: 72px;
    opacity: 1;
    right: 24px;
    bottom: 24px;
  }

  100% {
    width: 500px;
    height: calc(100vh - 30%);
    opacity: 1;
    right: 24px;
    bottom: 24px;
  }
}

.openChat {
  animation: openChat 0.5s ease-in-out;
  width: 500px;
  height: calc(100vh - 30%);
  opacity: 1;
  right: 24px;
  bottom: 24px;
}

@keyframes closeChat {
  0% {
    width: 500px;
    height: calc(100vh - 30%);
    opacity: 1;
    right: 24px;
    bottom: 24px;
  }

  40% {
    width: 500px;
    height: 72px;
    opacity: 1;
    right: 24px;
    bottom: 24px;
  }

  80% {
    width: 500px;
    height: 72px;
    opacity: 1;
    right: 24px;
    bottom: 24px;
  }

  100% {
    width: 500px;
    height: 72px;
    opacity: 1;
    right: 24px;
    bottom: 24px;
  }
}

.closeChat {
  animation: closeChat 1s ease-in-out;
  width: 500px;
  height: 72px;
  opacity: 1;
  right: 24px;
  bottom: 24px;
}

.buttonChat {
  position: fixed;
  background-color: var(--color-primary);
  height: 80px;
  width: 80px;
  aspect-ratio: 1/1;
  right: 24px;
  bottom: 24px;
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatWindowDialoHheader {
  color: var(--color-gray-500);
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;
  box-sizing: border-box;
}

@keyframes chatWindowDialogDialogOpen {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.chatWindowDialogDialogOpen {
  animation: chatWindowDialogDialogOpen 1s ease-in-out;
}

@keyframes chatWindowDialogDialogClose {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.chatWindowDialogDialogClose {
  animation: chatWindowDialogDialogClose 0.5s ease-in-out;
}

.chatWindowDialogDialog {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 64px;
}

.inputForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(500px - 16px);
  padding: 8px 16px;
  background-color: var(--color-gray-900);
  border-radius: 8px;
  box-sizing: border-box;
  position: fixed;
  bottom: 8px;
  right: 8px;
}

.inputMessage {
  height: 40px;
  width: 100%;
  border: none;
  background: transparent;
  font-size: 1rem;
  color: var(--color-gray-500);
}

.inputMessage::placeholder {
  color: var(--color-gray-500);
}

.inputMessage:focus {
  outline: none;
}

.inputMessage:focus::placeholder {
  color: var(--color-gray-800);
}

.message {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
  gap: 8px;
}

.message-User {
  background-color: var(--color-success);
  /* Цвет фона сообщения */
  color: #fff;
  /* Цвет текста сообщения */
  padding: 8px 16px;
  border-radius: 16px 16px 0 16px;
  position: relative;
  max-width: 70%;
  /* Максимальная ширина блока сообщения */
}

.tail-User {
  width: 0;
  height: 0;
  border-top: 16px solid transparent;
  /* Высота хвостика */
  border-left: 16px solid var(--color-success);
  /* Цвет хвостика */
  position: absolute;
  right: -8px;
  /* Смещение хвостика вправо */
  top: 100%;
  /* Выравнивание хвостика по вертикали */
  transform: translateY(-100%);
}

.message-Assistant {
  background-color: rgba(0, 0, 0, 0.4);
  /* Цвет фона сообщения */
  color: var(--color-gray-500);
  /* Цвет текста сообщения */
  padding: 8px 16px;
  border-radius: 16px 16px 16px 0;
  position: relative;
  max-width: 70%;
  /* Максимальная ширина блока сообщения */
}

.tail-Assistant {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  /* Высота хвостика */
  border-right: 8px solid rgba(0, 0, 0, 0.4);
  /* Цвет хвостика */
  position: absolute;
  left: -8px;
  /* Смещение хвостика влево */
  top: 100%;
  /* Выравнивание хвостика по вертикали */
  transform: translateY(-100%);
}

.confirmButton {
  height: 40px;
  padding: 0 16px;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 2px solid var(--color-alarm);
  cursor: pointer;
}

.cancelButton {
  height: 40px;
  padding: 0 16px;
  background-color: var(--color-success);
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
}

/* a {
    color: var(--color-white);
    text-decoration: underline;
} */

/* GPT helper end */

/* WigetRating */

.rating-container {
  display: flex;
  cursor: pointer;
}

.rating-star {
  color: var(--color-gray-600);
  /* Цвет звезды по умолчанию */
  user-select: none;
  /* Предотвращает выделение текста при двойном клике */
}

.rating-star.active {
  color: var(--color-warning);
  /* Цвет звезды при наведении или выборе */
}

.rating-star.active-1-2 {
  color: var(--color-alarm);
  /* Цвет звезды при наведении или выборе */
}

.rating-star.active-3 {
  color: var(--color-warning);
  /* Цвет звезды при наведении или выборе */
}

.rating-star.active-4-5 {
  color: var(--color-success);
  /* Цвет звезды при наведении или выборе */
}

/* WigetRating end */

/* offer */
/* Контент оффера*/
.offer-content-item {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
  /* padding-top: 24px; */
  /* background-color: var(--color-white); */
  box-sizing: border-box;
  /* border-radius: 24px; */
}

/* Номер раздела */
.section-number {
  grid-column: 1;
  text-align: left;
}

/* Заголовок раздела */
.section-title {
  grid-column: 2 / 12;
  color: var(--color-gray-800);
}

/* Номер подзаголовка */
.section-subnumber {
  grid-column: 2;
}

/* Подзаголовок раздела */
.section-subtitle {
  grid-column: 3 / 12;
  /* line-height: 1.5; */
}

/* Описание раздела */
.section-description {
  grid-column: 2 / 12;
  /* line-height: 1.5; */
  /* position: relative; */
  color: var(--color-gray-600);
  /* padding: 8px 0; */
  /* background-color: var(--color-gray-100);
    padding: 24px;
    border-radius: 8px; */
}

.section-description::before {
  content: "";
  position: absolute;
  left: -32px;
  top: 0;
  width: 4px;
  border-radius: 80px;
  height: 100%;
  background-color: var(--color-gray-100);
}

/* Определение */
.definition {
  grid-column: 2 / 5;
  /* font-size: 1rem; */
  font-weight: bold;
  text-align: right;
  color: var(--color-gray-800);
  /* line-height: 1.5; */
}

/* Описание определения */
.definition-description {
  grid-column: 5 / 12;
  color: var(--color-gray-800);
  /* line-height: 1.5; */
}

/* Номер пункта */
.item-number {
  grid-column: 2;
  color: var(--color-gray-800);
}

/* Описание пункта */
.item-description {
  grid-column: 3 / 12;
  color: var(--color-gray-800);
  /* line-height: 1.5; */
}

/* Не нумерованный список */
.unordered-list {
  grid-column: 4 / 12;
  color: var(--color-gray-800);
  /* line-height: 1.5; */
}

/* offer end */
