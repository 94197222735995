/* .block__element--modifier */

.popupWindow__content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  box-sizing: border-box;
  background-color: var(--color-white);
  backdrop-filter: blur(8px);
}

.popupWindow__content {
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: var(--width-mobile-content); */
  width: 100%;
  /* max-height: calc(100vh - 48px); */
  max-height: calc(100vh - 48px - 24px);
  height: 100%;
  margin-top: 72px;
}

.popupWindow__allert-content {
  border-radius: 16px;
  background-color: var(--color-white);
  max-width: var(--width-mobile-content);
  width: 100%;
  min-height: 240px;
}

.popupWindow-dropdown-list__content {
  border-radius: 16px 16px 0 0;
  background-color: var(--color-white);
  max-width: var(--width-mobile-content);
  width: 100%;
  max-height: calc(100% - 48px - 16px);
  height: auto;
}

.popupWindow__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  height: 56px;
  padding: 0 8px 0 24px;
  box-sizing: border-box;
  /* border-bottom: 1px solid var(--color-gray-200); */
}

.popupWindow__button-bar {
  /* max-width: 560px; */
  /* padding: 0 16px; */
  /* width: 100%; */
  /* height: 80px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
}

.popupWindow__button--close {
  height: 32px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 80px; */
  background-color: var(--color-white);
  cursor: pointer;
  /* background-color: var(--color-alarm); */
}

.popupWindow__options {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 56px);
  overflow-y: auto;
  /* background-color: var(--color-gray-100) */
  display: inline-flex;
  justify-content: center;
}

.popupWindow__options--content {
  width: var(--width-mobile-content);
}

.popupWindow__allert-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 56px);
  overflow-y: auto;
}

.popupWindow__children-content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popupWindow__allert-button-bottom-bar {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
}

.popupWindow__allert-button--cancel {
  height: 56px;
  width: 100%;
  border: none;
  border-radius: 8px;
  color: var(--color-white);
  cursor: pointer;
  background-color: var(--color-black);
}

.popupWindow__allert-button--confirm {
  height: 56px;
  width: 100%;
  border: none;
  border-radius: 8px;
  color: var(--color-white);
  background-color: var(--color-primary);
  cursor: pointer;
}

/* @keyframes shake {
  0% {
    transform: translateX(0);
  }
  10%,
  90% {
    transform: translateX(-15px);
  }
  20%,
  80% {
    transform: translateX(15px);
  }
  30%,
  70% {
    transform: translateX(-5px);
  }
  40%,
  60% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(0);
  }
}

.popup-error {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.no-scroll {
  overflow: hidden;
} */
